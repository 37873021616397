import React, { useEffect } from "react";
import CountUp from "react-countup";
import { MdOutlineFileDownload } from "react-icons/md";
import { RiInstagramLine } from "react-icons/ri";
import { BsFacebook } from "react-icons/bs";
import { GrYoutube } from "react-icons/gr";
import { IoLogoWhatsapp } from "react-icons/io5";

import ourclient from "../../assets/image/ourclients.png";
import Marquee from "../dummy/Marquee";
import counter1 from "../../assets/image/counter-1.webp";
import counter2 from "../../assets/image/counter-2.webp";
import counter3 from "../../assets/image/counter-3.webp";
import recpro2 from "../../assets/image/recpro2.png";
import recpro13 from "../../assets/image/recpro13.png";
import larsen from "../../assets/comlogo/larsen-turbo-logo.png";
import sree from "../../assets/comlogo/shree.png";
import simplex from "../../assets/comlogo/simplex.png";
import micr from "../../assets/comlogo/micr-transmission.png";
import wog from "../../assets/comlogo/WOG.png";
import kk from "../../assets/comlogo/kk-construction.png";
import kashyapi from "../../assets/comlogo/kashyapi.png";
import llyod from "../../assets/comlogo/llyod.png";
import steel from "../../assets/comlogo/steel.png";
import mccopy from "../../assets/comlogo/mcCoy.png";
import grasin from "../../assets/comlogo/grasim.png";
import ourclientbanner from "../../assets/image/ourclientbanner.webp";

const National = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const clientData = [
    // fabrication
    {
      name: "Larsen and Tubro Ltd",
      ScopeofWork:
        "M/S Wonder  Cement plant at Nimbahera Subcontract for Larsen and Tubro Ltd ",
      photo: larsen,
      desc: "The FPSO will be installed by MODEC offshore in a disconnectable yoke moored configuration at a design water depth of approximately 30 meters. The topsides facilities have the process capacities of 90kbpd oil, 80kbpd produced water, 75MMscfd gas and 120kbpd water injection. ",

      link: "/pp",
    },
    {
      name: "Larsen and Tubro Ltd",

      photo: larsen,
      desc: "The FPSO will be installed by MODEC offshore in a disconnectable yoke moored configuration at a design water depth of approximately 30 meters. The topsides facilities have the process capacities of 90kbpd oil, 80kbpd produced water, 75MMscfd gas and 120kbpd water injection. ",
    },
    {
      name: "Larsen and Tubro Ltd",

      photo: larsen,
      desc: "The FPSO will be installed by MODEC offshore in a disconnectable yoke moored configuration at a design water depth of approximately 30 meters. The topsides facilities have the process capacities of 90kbpd oil, 80kbpd produced water, 75MMscfd gas and 120kbpd water injection. ",

      link: "/pp",
    },
    {
      name: "Hazi Baba ",

      photo: sree,
      desc: "The FPSO will be installed by MODEC offshore in a disconnectable yoke moored configuration at a design water depth of approximately 30 meters. The topsides facilities have the process capacities of 90kbpd oil, 80kbpd produced water, 75MMscfd gas and 120kbpd water injection. ",

      link: "/pp",
    },
    {
      name: "Simplex Infrastruxture Pvt. Ltd",
      photo: simplex,
      desc: "The FPSO will be installed by MODEC offshore in a disconnectable yoke moored configuration at a design water depth of approximately 30 meters. The topsides facilities have the process capacities of 90kbpd oil, 80kbpd produced water, 75MMscfd gas and 120kbpd water injection. ",

      link: "/pp",
    },
    {
      name: "Micro Transmission Systen Pvt. Ltd",
      ScopeofWork: "M/s Micro Transmission Systen Pvt. Ltd., Noida ",
      photo: micr,
      desc: "The FPSO will be installed by MODEC offshore in a disconnectable yoke moored configuration at a design water depth of approximately 30 meters. The topsides facilities have the process capacities of 90kbpd oil, 80kbpd produced water, 75MMscfd gas and 120kbpd water injection. ",

      link: "/pp",
    },
    {
      name: "Micro Transmission Systen Pvt. Ltd",
      ScopeofWork: "M/s Micro Transmission Systen Pvt. Ltd., Noida",
      photo: micr,
      desc: "The FPSO will be installed by MODEC offshore in a disconnectable yoke moored configuration at a design water depth of approximately 30 meters. The topsides facilities have the process capacities of 90kbpd oil, 80kbpd produced water, 75MMscfd gas and 120kbpd water injection. ",

      link: "/pp",
    },
    {
      name: "WOG Group of Companies Pte. Ltd.",

      photo: wog,
      desc: "The FPSO will be installed by MODEC offshore in a disconnectable yoke moored configuration at a design water depth of approximately 30 meters. The topsides facilities have the process capacities of 90kbpd oil, 80kbpd produced water, 75MMscfd gas and 120kbpd water injection. ",

      link: "/pp",
    },
    {
      name: "K K Construction and Infra Pvt. Ltd .",

      photo: kk,
      desc: "The FPSO will be installed by MODEC offshore in a disconnectable yoke moored configuration at a design water depth of approximately 30 meters. The topsides facilities have the process capacities of 90kbpd oil, 80kbpd produced water, 75MMscfd gas and 120kbpd water injection. ",

      link: "/pp",
    },
    {
      name: "KM/s Kashyapi Infrastructue Pvt. Ltd.      ",

      photo: kk,
      desc: "The FPSO will be installed by MODEC offshore in a disconnectable yoke moored configuration at a design water depth of approximately 30 meters. The topsides facilities have the process capacities of 90kbpd oil, 80kbpd produced water, 75MMscfd gas and 120kbpd water injection. ",

      link: "/pp",
    },
    {
      name: "M/s Kashyapi Infrastructue Pvt. Ltd.      ",

      photo: kashyapi,
      desc: "The FPSO will be installed by MODEC offshore in a disconnectable yoke moored configuration at a design water depth of approximately 30 meters. The topsides facilities have the process capacities of 90kbpd oil, 80kbpd produced water, 75MMscfd gas and 120kbpd water injection. ",

      link: "/pp",
    },
    {
      name: "M/s Stelarin Pvt. Ltd.      ",

      photo:
        "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9?auto=compress&cs=tinysrgb&dpr=2&h=300&w=300",
      desc: "The FPSO will be installed by MODEC offshore in a disconnectable yoke moored configuration at a design water depth of approximately 30 meters. The topsides facilities have the process capacities of 90kbpd oil, 80kbpd produced water, 75MMscfd gas and 120kbpd water injection. ",

      link: "/pp",
    },
    {
      name: "M/s Micro Transmission Systen Pvt. Ltd.      ",

      photo: micr,
      desc: "The FPSO will be installed by MODEC offshore in a disconnectable yoke moored configuration at a design water depth of approximately 30 meters. The topsides facilities have the process capacities of 90kbpd oil, 80kbpd produced water, 75MMscfd gas and 120kbpd water injection. ",

      link: "/pp",
    },
    {
      name: "M/s Micro Transmission Systen Pvt. Ltd    ",

      photo: micr,
      desc: "The FPSO will be installed by MODEC offshore in a disconnectable yoke moored configuration at a design water depth of approximately 30 meters. The topsides facilities have the process capacities of 90kbpd oil, 80kbpd produced water, 75MMscfd gas and 120kbpd water injection. ",

      link: "/pp",
    },
    {
      name: "M/s Llyod Insulation Limited  ",

      photo: llyod,
      desc: "The FPSO will be installed by MODEC offshore in a disconnectable yoke moored configuration at a design water depth of approximately 30 meters. The topsides facilities have the process capacities of 90kbpd oil, 80kbpd produced water, 75MMscfd gas and 120kbpd water injection. ",

      link: "/pp",
    },
    {
      name: "Brahmpuri Steel Ltd. ",

      photo: steel,
      desc: "The FPSO will be installed by MODEC offshore in a disconnectable yoke moored configuration at a design water depth of approximately 30 meters. The topsides facilities have the process capacities of 90kbpd oil, 80kbpd produced water, 75MMscfd gas and 120kbpd water injection. ",

      link: "/pp",
    },
    {
      name: "McCoy Architectural System Pvt. Ltd",

      photo: mccopy,
      desc: "The FPSO will be installed by MODEC offshore in a disconnectable yoke moored configuration at a design water depth of approximately 30 meters. The topsides facilities have the process capacities of 90kbpd oil, 80kbpd produced water, 75MMscfd gas and 120kbpd water injection. ",

      link: "/pp",
    },
    {
      name: "Grasim Chemical Industries Ltd",

      photo: grasin,
      desc: "The FPSO will be installed by MODEC offshore in a disconnectable yoke moored configuration at a design water depth of approximately 30 meters. The topsides facilities have the process capacities of 90kbpd oil, 80kbpd produced water, 75MMscfd gas and 120kbpd water injection. ",

      link: "/pp",
    },
    // Rectofitting
    {
      name: " Structural Audit of Heritage Buildings at Oberoi Maidens Hotel, Delhi. ",

      photo: recpro2,
      desc: "The Oberoi Maidens Hotel, Delhi is a hotel property with Grade 1 heritage buildings located at Civil Lines, Delhi opened since 1903. The hotel property consists of majorly 3 no’s of heritage structures i.e.",

      link: "/pp",
    },
    // Waste managements
    {
      name: " M/s. ANTONY LARA ENVIRO SOLUTIONS P LTD ",

      photo: recpro13,
      desc: "Remediation of temporary dumpsite near lakhnawali village at greater Noida through effective bio mining and mechanized means with complete reclamation of the dumpsite land for M/s. ANTONY LARA ENVIRO SOLUTIONS P LTD",

      link: "/pp",
    },
    {
      name: "M/s United Facilities and Logistics Private Limited.      ",

      photo: recpro13,
      desc: "Work of waste segregating trommels, machinery required for the feeding to trommel from dumpsite to trommel site and creating stacks of bio-mined output i.e., inert, RDF and C&D waste for bio-mining and bio-remediation work at Bhalswa, Ghazipur and Okhla dumpsite for M/s United Facilities and Logistics Private Limited",

      link: "/pp",
    },
  ];

  const row2 = [sree, simplex, micr, wog, kk, kashyapi, llyod, steel];
  const row1 = [sree, simplex, micr, wog, kk, kashyapi, llyod, steel];
  return (
    <>
      <div>
        {/* Banner section */}

        <div>
          <div className=" bottom-0 w-full mt-20 bg-no-repeat careers">
            <img
              className="h-[30rem] w-full opacity-100 brightness-50"
              src={ourclientbanner}
              alt=""
            />
          </div>
          <div className="absolute lg:top-32 lg:left-32 sm:top-20 sm:left-5 text-white text-2xl tracking-wide sm:mr-5">
            <p className="mt-10 text-4xl font-bold ">Our Clients</p>
            <p className="text-white lg:w-[50%] mt-10 lg:text-lg sm:text-base">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nisi
              corporis sapiente vitae unde sed, quibusdam suscipit quam quidem
              repudiandae perferendis magni voluptatibus voluptas aliquid porro
              placeat deleniti saepe ut dolor.
            </p>

            <div className=" mt-10">
              {/* <a href={solidwasteppt} target="_blank"> */}

              <div>
                <div className=" mt-10 flex">
                  {/* <a href={solidwasteppt} target="_blank"> */}
                  <button
                    class="bg-yellow-200 hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border-2 border-blue-900 hover:border-transparent 
                rounded flex flex-row"
                  >
                    <span>Download</span>
                    <span className="text-xl ml-5">
                      <MdOutlineFileDownload />
                    </span>
                  </button>
                  {/* <span className=" text-yellow-200 text-5xl mx-4">
                    <RiInstagramLine />
                  </span>
                  <span className=" text-yellow-200 text-5xl mx-2">
                    <BsFacebook />
                  </span>
                  <span className=" text-yellow-200 text-5xl mx-2">
                    <GrYoutube />
                  </span>
                  <span className=" text-yellow-200 text-5xl mx-4">
                    <IoLogoWhatsapp />
                  </span> */}
                  {/* </a> */}
                </div>
              </div>
              {/* </a> */}
            </div>
          </div>
        </div>

        {/* Connect with our experts */}
        <div className="bg-gray-300">
          <section class=" lg:pt-[30px] ">
            <div className="flex flex-col items-center justify-center w-full px-0 lg:px-10 xl:px-20">
              <h2 className="text-3xl md:text-2xl lg:text-3xl xl:text-4xl text-primary-matte-black font-[600] text-center text-[#002E5B]">
                Fabrication Clients
              </h2>
              <hr className="h-2 w-10 mt-2 rounded-full bg-yellow-400 mr-52" />
            </div>

            {/* <div class="container mx-auto grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 py-10 gap-10">
              {clientData?.map((data, i) => (
                <div class="-mx-4 ">
                  <div class="w-full px-4 ">
                    <div
                      className="mx-a mb-10 max-w-[370px] bg-white flex flex-col text-center h-[32rem] relative"
                      key={i}
                    >
                      <div className="flex mb-4 overflow-hidden rounded justify-center">
                        <img
                          className="rounded-full h-20 w-20 mt-5 "
                          src={data.img}
                          alt="image"
                        />
                      </div>
                      <div className=" mx-5">
                        <h2 class=" inline-block rounded py-1 text-lg font-semibold leading-loose text-black ">
                          {data.company} <br />
                          {data.name}
                        </h2>
                        <p className="text-base">{data.price}</p>
                        <h2 className="inline-block rounded py-1 text-lg font-semibold leading-loose text-black mt-5">
                          Description
                        </h2>
                        <p className="text-base">{data.Description}</p>
                      </div>
                      <div className=" flex justify-between mx-10 absolute bottom-10 gap-10 text-2xl  ">
                        <Link
                          className="hover:text-blue-400"
                          to={data.instaLink}
                        >
                          <RiInstagramLine />
                        </Link>
                        <Link className="hover:text-blue-400" to={data.facLink}>
                          <BsFacebook />
                        </Link>
                        <Link className="hover:text-blue-400" to={data.youLink}>
                          <GrYoutube />
                        </Link>
                        <Link
                          className="hover:text-blue-400"
                          to={data.likdLink}
                        >
                          <FaLinkedin />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div> */}
          </section>
          {/* Clients section */}

          <div className="w-[90%] m-auto ">
            <div className="mt-20">
              <div className="grid grid-cols-12">
                {clientData.map((item) => (
                  <div className="bg-white h-[500px] text-black rounded-xl lg:col-span-4 md:col-span-6 sm:col-span-12 mx-3 my-2">
                    <div className="h-56 rounded-t-xl bg-indigo-500 flex justify-center items-center">
                      <img
                        src={item.photo}
                        alt=""
                        className="h-44 w-44 rounded-full"
                      />
                    </div>
                    <div className="flex flex-col justify-center items-center gap-4 p-4">
                      <p className="text-lg text-gray-500 font-medium">
                        {item.name}
                      </p>
                      <p className="mt-3 text-center">{item.desc}</p>
                      {/* <div>Price: {item.price}</div> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* counter section */}

        <div className="mt-20">
          {/* <div className="flex flex-col items-center justify-center w-full px-0 lg:px-10 xl:px-20">
            <h2 className="text-3xl md:text-2xl lg:text-3xl xl:text-4xl text-primary-matte-black font-[600] text-center text-[#002E5B]">
              Clients Serivices
            </h2>
            <hr className="h-2 w-10 mt-2 rounded-full bg-yellow-400 mr-40" />
          </div> */}

          {/* counter section */}
          <div className="counter w-[90%] lg:h-[10rem] m-auto flex flex-col justify-center align-middle text-center">
            <div className="grid grid-cols-12">
              <div className="lg:col-span-4 sm:col-span-12  font-[DM Serif Display] counter-class flex  justify-center">
                <div className="">
                  <img src={counter1} alt="" className="h-16 w-16 mx-2" />
                </div>
                <div>
                  <span className="text-5xl text-blue-500 font-medium">
                    <CountUp start={0} end={1750} delay={5}>
                      +
                    </CountUp>
                  </span>

                  <h1 className="font-[600]">HAPPY CLIENTS</h1>
                </div>
              </div>
              <div className="lg:col-span-4 sm:col-span-12 sm:my-10 lg:my-0  font-[DM Serif Display] counter-class flex justify-center">
                <div>
                  <img src={counter2} alt="" className="h-16 w-16 mx-2" />
                </div>
                <div>
                  <span className="text-5xl text-blue-500 font-medium">
                    <CountUp start={0} end={2000} delay={5}></CountUp>
                  </span>

                  <h1 className="font-[600]">FINISHED PROJECTS</h1>
                </div>
              </div>
              <div className="lg:col-span-4 sm:col-span-12  font-[DM Serif Display] counter-class flex justify-center">
                <div>
                  <img src={counter3} alt="" className="h-16 w-16 mx-2" />
                </div>
                <div>
                  <span className="text-5xl text-blue-500 font-medium">
                    <CountUp start={0} end={1750} delay={5}>
                      +
                    </CountUp>
                  </span>

                  <h1 className="font-[600]">Working Projects</h1>
                </div>
              </div>
            </div>
          </div>

          {/* marqee */}
        </div>
        <Marquee row1={row1} row2={row2} />
      </div>
    </>
  );
};

export default National;
