import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { FaCloudDownloadAlt } from "react-icons/fa";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./styles.css";

// import required modules
import { Pagination, Autoplay } from "swiper/modules";

import gas from "../../assets/image/gas.jpg";
import slider from "../../assets/image/slider.jpg";
import plants from "../../assets/image/plants.jpg";
import refineries from "../../assets/image/refineries.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function Sliders() {
  const imge = [
    {
      img: gas,
    },
    {
      img: slider,
    },
    {
      img: gas,
    },
    {
      img: gas,
    },
  ];
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <>
      <div className="w-full relative ">
        <Slider {...settings}>
          <img
            src={gas}
            alt="main banner"
            className="rounded-lg w-full lg:h-screen sm:h-[15rem] brightness-50"
          />
          <img
            src={slider}
            alt="main banner"
            className="rounded-lg w-full lg:h-screen sm:h-[15rem] brightness-50"
          />
          <img
            src={plants}
            alt="main banner"
            className="rounded-lg w-full lg:h-screen sm:h-[15rem] brightness-50"
          />
          <img
            src={refineries}
            alt="main banner"
            className="rounded-lg w-full lg:h-screen sm:h-[15rem] brightness-50"
          />
        </Slider>
        <div className="main-banner-contant absolute lg:top-40 lg:left-40 sm:top-20 sm:left-10 animate-fade-up sm:mx-5">
          <h4 className="text-[#fc904a] text-lg font-semibold tracking-wide">
            FOSTERTECH PVT. LTD
          </h4>
          <h5 className="lg:text-7xl sm:text-3xl font-bold text-white my-5 uppercase tracking-wide animate-flip-up animate-once animate-duration-1000 animate-delay-1000">
            Special Sale
          </h5>
          <p className="lg:text-lg sm:text-sm lg:w-[60rem] sm:[18rem] text-white">
            FPL is a segment of Fostertechs Group developed in Kharbanda
            Industrial area.{" "}
            <span className="sm:hidden lg:block">
              We acquaint ourselves as structural and mechanical fabrication and
              erection engineers. We proffer panoramic services that encompasses
              Design, fabrication, supply, erection, building retrofitting,
              solid and waste management etc. FFPL have combined expertise of
              more than 40 years in fabrication and project management of
              industrial onshore and offshore oil and gas projects. Factory is
              equipped with modern and adverse technological facilities for the
              manufacturing structures with capacity of 2000 metric tons per
              year. Fostertechs many partners with skilled manpower supply
              contractors to execute the work in more efficient manner.
            </span>
          </p>
          {/* <button className="button lg:mt-16 sm:mt-5 px-5 py-2 text-lg bg-[#FEC006] hover:bg-[#2c281f] hover:text-white flex gap-3 items-center">
            <span>VIEW MORE </span>
            <span className="text-2xl">
              <FaCloudDownloadAlt />
            </span>
          </button> */}
        </div>
      </div>
    </>
  );
}
