import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebookF, FaLinkedin } from "react-icons/fa";
import { AiFillGithub, AiOutlineTwitter } from "react-icons/ai";
// import { useSelector } from "react-redux";
import { AiFillShopping, AiFillHeart } from "react-icons/ai";
import Logo from "../../assets/image/logo_up.png";
import { FaGithub, FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import aboutimg from "../../assets/image/5757169_2965738.jpg";
import { MdOutlineMailOutline } from "react-icons/md";

const Footer = () => {
  const Year = new Date().getFullYear();
  const navigate = useNavigate();

  return (
    <div className="">
      <footer className="relative  text-white">
        <div className="absolute top-0 left-0 w-[100%] overflow-hidden bg-[#243945]">
          {/* <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,
                250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,
                3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="relative block h-[600px] fill-white"
            ></path>
          </svg> */}
          <div className="grid lg:grid-cols-4 gap-20 sm:grid-cols-1 px-20 py-10">
            <div className="flex flex-col gap-5">
              <div className=" transition duration-150 ease-in-out text-white">
                <img className="w-38 h-20" src={Logo} alt="" />
              </div>

              <p>
                <span>
                  {" "}
                  Fostertechs Pvt. Ltd, an ISO-9001:2016 certified company with
                  its headquarters in New Delhi, India, is a group of highly
                  motivated, qualified and experienced technocrats. Our team has
                  enormous experience in.
                </span>
                <Link to="/" className="text-blue-600">
                  {" "}
                  Read More
                </Link>
              </p>
            </div>

            <div className="">
              <div className="flex flex-col gap-5">
                {/* <div className="hover:bg-gray-400 transition duration-150 ease-in-out">
                  <img className="w-full h-32" src={aboutimg} alt="" />
                </div> */}

                <Link to="/">
                  <div className="">
                    <h2 className="text-2xl">Information</h2>
                  </div>
                </Link>
              </div>
              <ul className="text-gray-400">
                <li className="text-[22px] list-none font-semibold text-pink-500 "></li>
                <li className="my-4 list-none hover:text-blue-500">
                  <Link to="">Structural Engineering</Link>
                </li>
                <li className="my-4 list-none hover:text-blue-500">
                  <Link to="">Revamping Services</Link>
                </li>
                <li className="my-4 list-none hover:text-blue-500">
                  <Link to="">Mechanical Engineering</Link>
                </li>
                <li className="my-4 list-none hover:text-blue-500">
                  <Link to="">Project Management Consultancy</Link>
                </li>
                <li className="my-4 list-none hover:text-blue-500">
                  <Link to="">Process Engineering</Link>
                </li>
                <li className="my-4 list-none hover:text-blue-500">
                  <Link to="">Software Capabilities</Link>
                </li>
              </ul>
            </div>

            <div>
              <div className="flex flex-col gap-5">
                {/* <div className="hover:bg-gray-400 transition duration-150 ease-in-out">
                  <img className="w-full h-32" src={aboutimg} alt="" />
                </div> */}

                <Link to="/">
                  <div className="">
                    <h2 className="text-2xl">Legal</h2>
                  </div>
                </Link>
              </div>
              <ul className="text-gray-400">
                <li className="my-4 list-none hover:text-blue-500">
                  <Link to="">Projects Initiation</Link>
                </li>
                <li className="my-4 list-none hover:text-blue-500">
                  <Link to="">Projects Execution</Link>
                </li>
                <li className="my-4 list-none hover:text-blue-500">
                  <Link to="">Project Closure </Link>
                </li>
                <li className="my-4 list-none hover:text-blue-500">
                  <Link to="">General </Link>
                </li>
                <li className="my-4 list-none hover:text-blue-500">
                  <Link to="">Onshore Projects</Link>
                </li>
                <li className="my-4 list-none hover:text-blue-500">
                  <Link to="">Water Treatment Projects</Link>
                </li>
                <li className="my-4 list-none hover:text-blue-500">
                  <Link to="">Projects Plannings</Link>
                </li>
              </ul>
            </div>
            <div className="mb-4 md:mb-0">
              <div className="flex flex-col gap-5">
                {/* <div className="hover:bg-gray-400 transition duration-150 ease-in-out">
                  <img className="w-full h-32" src={aboutimg} alt="" />
                </div> */}

                <Link to="/">
                  <div className="">
                    <h2 className="text-2xl">Contact Us</h2>
                  </div>
                  {/* <div className="bg-white w-full text-center pb-5">
                    <h2 className="text-3xl text-pink-500">Waste Management</h2>
                  </div> */}
                </Link>
              </div>
              <div className="text-gray-400">
                <p className="text-[16px] my-4">
                  1, Brijpuri Extension, Parwana Road, New Delhi – 110051
                </p>
                <p className="text-[16px] my-4">
                  Phone: +91 98104 54656, <br /> 99992 68782{" "}
                </p>
                <p className="text-[16px] my-4 flex">
                  <span>
                    {" "}
                    info@fostertechs.com, <br />
                    fostertechs@gmail.com
                  </span>
                </p>
              </div>
              <div className="flex space-x-4">
                <a
                  className="text-white hover:text-pink-500 transform hover:scale-150 
                    transition-all duration-150 ease-in-out text-3xl"
                  href=""
                >
                  <FaGithub />
                </a>
                <a
                  className="text-white hover:text-pink-500 transform hover:scale-150
                     transition-all duration-150 ease-in-out text-3xl"
                  href=""
                >
                  <FaLinkedinIn />
                </a>
                <a
                  className="text-white hover:text-pink-500 transform hover:scale-150
                     transition-all duration-150 ease-in-out text-3xl"
                  href=""
                >
                  <FaTwitter />
                </a>
                <a
                  className="text-white hover:text-pink-500 transform hover:scale-150
                     transition-all duration-150 ease-in-out text-3xl"
                  href=""
                >
                  <FaInstagram />
                </a>
              </div>
              <Link to="/get-start" className="">
                <button className="bg-yellow-400 text-black font-medium text-lg px-6 py-3 rounded-full md:block hidden mt-5">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <div className="py-10 border-t-2 border-gray-500">
            <h6 className="text-center">
              &copy; Copyright © {Year} Fostertechs. All rights reserved.
            </h6>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
